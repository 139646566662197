import us from './flag/us.svg';
import germany from './flag/germany.svg';
import spain from './flag/spain.svg';
import saudi from './flag/saudi.png';
import chinese from './flag/china.svg';
import israel from './flag/israel.svg';

export default [
  {
    id: 'en',
    language: 'en',
    icon: us,
  },
  // {
  //   id: 'de',
  //   language: 'de',
  //   icon: germany,
  // },
  // {
  //   id: 'es',
  //   language: 'es',
  //   icon: spain,
  // },
  // {
  //   id: 'ar',
  //   language: 'ar',
  //   icon: saudi,
  // },
  // {
  //   id: 'zh',
  //   language: 'zh',
  //   icon: chinese,
  // },
  // {
  //   id: 'he',
  //   language: 'he',
  //   icon: israel,
  // },
];
